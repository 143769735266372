import "./App.css";
import Navigation from "./navigation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Web3EVMModal } from "./components/Web3EVMModal";
import Web3Provider from "./hooks/Web3Provider";

function App() {
  return (
    <Web3EVMModal>
      <Web3Provider>
        <div className="App">
          <Navigation />
          <ToastContainer position="top-right" autoClose={10000} />
        </div>
      </Web3Provider>
    </Web3EVMModal>
  );
}

export default App;
