"use client";

import React from "react";
import { createAppKit } from "@reown/appkit/react";
import {
  mainnet,
  arbitrum,
  bsc,
  polygon,
  fantom,
  bscTestnet,
} from "@reown/appkit/networks";
import { Ethers5Adapter } from "@reown/appkit-adapter-ethers5";

const projectId = "09e2e758f5a9a0de0026cf810d7349ee";

const metadata = {
  name: "FYTCOIN",
  description: "Fytcoin Platform",
  url: "https://fytcoin.io",
  icons: ["https://fytcoin.io/android-512x512.png"],
};

createAppKit({
  adapters: [new Ethers5Adapter()],
  networks: [mainnet, bsc, polygon, arbitrum, fantom, bscTestnet],
  metadata,
  projectId,
  features: {
    analytics: true,
  },
  defaultNetwork: bsc,
});

export function Web3EVMModal({ children }) {
  return <div className="">{children}</div>;
}
