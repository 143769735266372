import React, { useEffect } from "react";
import { useAppKitAccount, useAppKitNetwork } from "@reown/appkit/react";
import { ethers } from "ethers";
import BLOCKSAFU_ABI from "../abi/blocksafu-abi.json";
import { useWeb3Context } from "../hooks/Web3Context";

export function WalletConnectEVM() {
  const { address, isConnected } = useAppKitAccount();
  const { chainId } = useAppKitNetwork();
  const {
    setAddress,
    setAddressGlobal,
    setChainId,
    setBlockSafuBalanceGlobal,
  } = useWeb3Context();

  useEffect(() => {
    if (isConnected) {
      const fetchData = async () => {
        setAddress(address);
        setAddressGlobal(address);
        setChainId(chainId);
        const BSC_RPC_URL = "https://bsc-dataseed2.defibit.io/";
        const bscProvider = new ethers.providers.JsonRpcProvider(BSC_RPC_URL);
        const BLOCKSAFU_TOKEN_ADDRESS =
          "0x32bFd701655EDF95809EaA5e525F0024ea571267";

        const tokenContractOnBSC = new ethers.Contract(
          BLOCKSAFU_TOKEN_ADDRESS,
          BLOCKSAFU_ABI,
          bscProvider
        );
        const blockSafuBalanceOnBSC = await tokenContractOnBSC.balanceOf(
          address || "0x0000000000000000000000000000000000000001"
        );
        const formattedBalance = ethers.utils.formatEther(
          blockSafuBalanceOnBSC
        );
        setBlockSafuBalanceGlobal(formattedBalance);
      };
      fetchData();
    } else {
      setAddress(null);
      setAddressGlobal(null);
      setChainId(null);
    }
  }, [address, chainId, isConnected, setAddress, setAddressGlobal, setChainId]);

  return (
    <div className="">
      <w3m-button />
    </div>
  );
}
