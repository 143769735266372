import React, { Fragment } from "react";
import { Header, Footer } from "../components/fytcoin-ui";
import { NavLink } from "react-router-dom";
import {
  RiDiscordLine,
  RiMediumLine,
  RiShoppingCart2Line,
  RiTelegramLine,
  RiTwitterLine,
} from "react-icons/ri";
export default function Home() {
  return (
    <>
      <Header />
      <section className="relative px-4 py-4 overflow-hidden bg-gray-100 md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">
        <div className="space-y-6 flex flex-col items-center gap-4">
          <div className="py-2 px-4 rounded-md border border-grayc-200 bg-white w-max dark:bg-gray-900 dark:text-yellow-500 dark:border-yellow-500">
            Easy Way Earn Profits!
          </div>
          <div className="space-y-4">
            <h1 className="text-3xl font-bold text-center leading-normal lg:leading-loose md:text-5xl lg:text-5xl xl:text-5xl bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_8%,_#9f7928_30%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] inline-block text-transparent bg-clip-text">
              The Future of Secure and <br />
              <div className="pt-0 lg:pt-2">Rewarding Cryptocurrency</div>
            </h1>
          </div>

          <p className="text-xl text-center text-grayc-400 dark:text-gray-400">
            Revolutionary Staking with "Spawns Pool" <br />- Earn Up to 110% in
            2 Years!
          </p>
          <div className="flex justify-center gap-5">
            <NavLink
              to="/commit"
              className="px-8 py-4 text-white bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_8%,_#9f7928_30%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] rounded-2xl"
            >
              Commit
            </NavLink>
            <NavLink
              to="/spawn"
              className="px-8 py-4 text-white bg-grayc-950 dark:bg-grayc-700 dark:text-gray-200 hover:bg-gray-400 rounded-2xl"
            >
              Spawn
            </NavLink>
          </div>
        </div>
      </section>
      <section className="relative space-y-12 px-2 py-4 overflow-hidden bg-white md:px-12 lg:px-28 dark:bg-grayc-900 md:py-20 lg:py-20 font-body">
        <div className="px-6 py-6 mb-32 bg-grayc-950 md:p-10 lg:p-10 xl:px-12 xl:pt-12 xl:pb-24 rounded-xl dark:bg-grayc-700">
          <div className="flex items-center justify-between w-full">
            <div>
              <img
                src="/images/illustration/illustration-1.webp"
                className="absolute -mt-4 -ml-12"
                width="50"
                height="50"
                alt="shape"
              />
            </div>
            <div>
              <img
                src="/images/illustration/illustration-2.webp"
                className="absolute mt-12 -ml-0"
                width="50"
                height="50"
                alt="shape"
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-xl ml-0 lg:ml-6 font-semibold leading-tight text-white md:text-xl lg:text-2xl xl:text-2xl dark:text-white">
              Unlock Incredible Rewards <br /> with Spawns Pool
            </h1>
            <div className="text-white text-end">
              ForYouTreaSure’s unique staking system, <br /> Spawns Pool, offers
              unmatched flexibility and returns
            </div>
          </div>
          <div className="relative mt-6">
            <div className="flex overflow-x-scroll flex-row justify-between w-full md:flex-row lg:flex-row xl:flex-row gap-8 absolute z-20">
              <div className="text-xl font-semibold whitespace-nowrap text-black p-6 shadow-md shadow-grayc-200 w-full dark:text-white bg-white rounded-lg dark:bg-grayc-950 dark:shadow-gray-700">
                2-Year Staking
                <div className="text-grayc-400 font-normal text-sm h-10 mt-2">
                  Earn a massive 110% return on your investment!
                </div>
                <h1 className="text-end text-3xl font-bold text-yellowc-400 mt-4">
                  110%
                </h1>
              </div>
              <div className="text-xl font-semibold whitespace-nowrap text-black p-6 shadow-md shadow-grayc-200 w-full dark:text-white bg-white rounded-lg dark:bg-grayc-950 dark:shadow-gray-700">
                1-Year Staking
                <div className="text-grayc-400 font-normal text-sm h-10 mt-2">
                  Get 50% income, effortlessly.
                </div>
                <h1 className="text-end text-3xl font-bold text-yellowc-400 mt-4">
                  50%
                </h1>
              </div>
              <div className="text-xl font-semibold whitespace-nowrap text-black p-6 shadow-md shadow-grayc-200 w-full dark:text-white bg-white rounded-lg dark:bg-grayc-950 dark:shadow-gray-700">
                6-Month Staking
                <div className="text-grayc-400 font-normal text-sm h-10 mt-2">
                  Enjoy 20% returns in half a year.
                </div>
                <h1 className="text-end text-3xl font-bold text-yellowc-400 mt-4">
                  20%
                </h1>
              </div>
              <div className="text-xl font-semibold whitespace-nowrap text-black p-6 shadow-md shadow-grayc-200 w-full dark:text-white bg-white rounded-lg dark:bg-grayc-950 dark:shadow-gray-700">
                1-Month Staking
                <div className="text-grayc-400 font-normal text-sm h-10 mt-2">
                  Secure 3% gains for short-term stakers.
                </div>
                <h1 className="text-end text-3xl font-bold text-yellowc-400 mt-4">
                  3%
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="p-10 bg-white md:p-16 lg:p-12 xl:p-12 rounded-3xl dark:bg-grayc-700">
          <img
            src="/images/illustration/rocket-moon-illustration.webp"
            width="160px"
            className="absolute right-0 -mt-40 mr-8"
            alt="Rocket"
          />
          <div className="flex items-center justify-between w-full">
            <div className="mb-3 text-2xl font-semibold text-yellowc-400">
              About Us
            </div>
          </div>
          <h1 className="text-xl font-bold leading-tight text-gray-900 md:text-3xl lg:text-3xl xl:text-5xl dark:text-white">
            What is FYT
          </h1>
          <h2 className="my-5 text-base lg:text-xl text-black dark:text-white">
            ForYouTreaSure (FYT) is not just another cryptocurrency—it’s a
            revolutionary asset built to deliver both stability and substantial
            returns. Whether you’re a trader, investor, or crypto enthusiast,
            FYT offers a cutting-edge experience that combines innovative
            features, security, and consistent rewards. With FYT, you’re not
            just investing in a token; you’re becoming part of a platform that
            values fairness and long-term growth. Our Spawns Pool staking system
            allows you to earn passive income on your terms—up to 110% rewards
            over two years, with payouts made every hour. Choose from flexible
            staking periods that cater to both short-term and long-term goals.
            We’ve designed FYT with you in mind—no bot interference, no
            transaction suspensions, just smooth, uninterrupted trading. Plus,
            our 2% trading tax ensures continued platform development, fueling
            the future of ForYouTreaSure and driving innovation. All of this is
            housed in a modern Dapp with a sleek, intuitive interface that makes
            managing your assets simple and enjoyable. Backed by audited
            contracts and robust liquidity, FYT gives you the confidence to
            trade and stake with peace of mind. Join the ForYouTreaSure
            revolution today—because your financial success starts here.
          </h2>
          <div className="flex justify-end w-full">
            <a
              href="/staking"
              className="flex items-center gap-2 px-8 py-4 text-white bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_8%,_#9f7928_30%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] hover:bg-yellow-700 rounded-2xl"
            >
              <RiShoppingCart2Line /> Buy Coin
            </a>
          </div>
        </div>

        <div className="p-6 my-16 bg-white md:p-16 lg:p-16 xl:p-16 rounded-3xl dark:bg-grayc-700">
          <div className="flex flex-col lg:flex-row gap-10 items-center justify-between w-full mt-12">
            <img
              src="/images/illustration/fyt-illustration.png"
              className=""
              width="300"
              height="300"
              alt="shape"
            />
            <div className="space-y-4 w-full bg-grayc-950 p-8 rounded-lg">
              <div className="flex flex-col lg:flex-row w-full items-center">
                <div className="w-full lg:w-8/12 space-y-4">
                  <h1 className="text-lg lg:text-2xl text-white font-semibold">
                    Tokenomics & Token Information
                  </h1>

                  <div className="flex justify-start w-full items-center pt-4">
                    <div className="font-semibold text-yellowc-400 w-1/2">
                      Token Name
                    </div>
                    <div className="font-semibold text-white w-1/2">
                      ForYouTreaSure
                    </div>
                  </div>
                  <div className="flex justify-start w-full items-center">
                    <div className="font-semibold text-yellowc-400 w-1/2">
                      Token Symbol
                    </div>
                    <div className="font-semibold text-white w-1/2">FYT</div>
                  </div>
                  <div className="flex justify-start w-full items-center">
                    <div className="font-semibold text-yellowc-400 w-1/2">
                      Decimals
                    </div>
                    <div className="font-semibold text-white w-1/2">18</div>
                  </div>
                  <div className="flex justify-start w-full items-center">
                    <div className="font-semibold text-yellowc-400 w-1/2">
                      Dev Tax
                    </div>
                    <div className="font-semibold text-white w-1/2">2%</div>
                  </div>
                  <div className="flex justify-start w-full items-center">
                    <div className="font-semibold text-yellowc-400 w-1/2">
                      Total Supply
                    </div>
                    <div className="font-semibold text-white w-1/2">
                      10,000,000 FYT
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-4/12 flex justify-end items-end">
                  <img
                    src="/images/illustration/illustration-3.png"
                    className="mt-16"
                    width="100"
                    height="100"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 mb-2 md:p-16 lg:p-16 xl:p-16 rounded-3xl">
          <h2 className="mb-12 text-2xl lg:text-5xl font-bold leading-tight bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_20%,_#9f7928_40%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] text-transparent bg-clip-text text-center">
            Join to our community
          </h2>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
            <a
              href="https://"
              className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-grayc-700 dark:text-white"
            >
              <h1 className="text-lg font-bold">Twitter</h1>
              <h2 className="my-3 text-gray-700 dark:text-gray-500">
                General Announcements
              </h2>
              <div className="flex justify-end">
                <RiTwitterLine className="text-3xl" />
              </div>
            </a>
            <a
              href="https://"
              className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-grayc-700 dark:text-white"
            >
              <h1 className="text-lg font-bold">Telegram</h1>
              <h2 className="my-3 text-gray-700 dark:text-gray-500">
                General Announcements
              </h2>
              <div className="flex justify-end">
                <RiTelegramLine className="text-3xl" />
              </div>
            </a>
            <a
              href="https://"
              className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-grayc-700 dark:text-white"
            >
              <h1 className="text-lg font-bold">Discord</h1>
              <h2 className="my-3 text-gray-700 dark:text-gray-500">
                General Announcements
              </h2>
              <div className="flex justify-end">
                <RiDiscordLine className="text-3xl" />
              </div>
            </a>
            <a
              href="https://"
              className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-grayc-700 dark:text-white"
            >
              <h1 className="text-lg font-bold">Medium</h1>
              <h2 className="my-3 text-gray-700 dark:text-gray-500">
                General Announcements
              </h2>
              <div className="flex justify-end">
                <RiMediumLine className="text-3xl" />
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
