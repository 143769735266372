import React, { Fragment, useContext, useEffect, useState } from "react";
import { Header, Footer, LoadingWrapper } from "../components/fytcoin-ui";
import {
  RiArrowUpLine,
  RiExchangeDollarLine,
  RiExternalLinkLine,
  RiFileCopy2Line,
  RiTimer2Line,
  RiWalletLine,
} from "react-icons/ri";
import { ClipboardCopy } from "../components/ClipboarCopy";
import {
  amountFormat,
  formatNumber,
  formatNumberWithComma,
  truncate,
} from "../libs/WebooLib";
import {
  useCheckIsNeedApprove,
  useDynamicCallFunction,
  useDynamicSendFunction,
  useGetERC20Detail,
} from "../hooks/web3hook";
import { Context } from "../Store";
import { getAppByChainId, getEnv } from "../libs/Env";
import { copyToClipboard, parseFromWei } from "../libs/utils";
import CurrencyInput from "react-currency-input-field";
import { BigNumber } from "bignumber.js";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useFirestore, useRefCode } from "../hooks/useFirestore";
import { useParams } from "react-router-dom";
import axios from "axios";
const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";
export default function Home() {
  const [
    { tokenSymbol, tokenBalance, tokenDecimal, isLoadingTokenInfo },
    fetchTokenInfo,
  ] = useGetERC20Detail();
  const [
    {
      tokenSymbol: tokenSymbolStake,
      tokenBalance: tokenBalanceStake,
      isLoadingTokenInfo: isLoadingTokenInfoStake,
    },
    fetchTokenInfoStake,
  ] = useGetERC20Detail();
  const [state, dispatch] = useContext(Context);
  const { app, db, refCode, isLoading } = useRefCode();
  const [totalReferrer, setTotalReferrer] = useState(0);
  const [percentDenominator, setPercentDenominator] = useState(0);
  const [percentForReferral, setPercentForReferral] = useState(0);
  const [minimumStakeInDollars, setMinimumStakeInDollars] = useState(0);
  const [minimumStakeInToken, setMinimumStakeInToken] = useState(0);
  const [amountStake, setAmountStake] = useState(0);
  const [totalPeriod, setTotalPeriod] = useState(0);
  const [isValidAmountStake, setIsValidAmountStake] = useState(false);
  const [availableReward, setAvailableReward] = useState(0);
  const [amountStakeInDollar, setAmountStakeInDollar] = useState(0);
  const [availableRewardFromReferral, setAvailableRewardFromReferral] =
    useState(0);
  const [claimedReward, setClaimedReward] = useState(0);
  const [claimedRewardFromReferral, setClaimedRewardFromReferral] = useState(0);
  const [{ isLoading: isLoadingDynamicCall }, dynamicCall] =
    useDynamicCallFunction();
  const [{ result: resultSend, isLoading: isLoadingSend }, dynamicSend] =
    useDynamicSendFunction();
  const [
    { amountAllowance, isNeedApprove, isLoadingCheck, txHashApprove },
    fetchCheck,
    fecthApprove,
  ] = useCheckIsNeedApprove();
  const [parentReferralAddress, setParentReferralAddress] = useState("");
  const [usdtDecimal, setUsdtDecimal] = useState(18);

  const { putData, getRow } = useFirestore();
  const searchParams = new URLSearchParams(document.location.search);
  const [priceUSD, setPriceUSD] = useState(0);
  const [volumeUSD24h, setVolumeUSD24h] = useState(0);

  const initialize = () => {
    getParentReferral();
    fetchTokenInfo(getAppByChainId(state.chainId).TOKEN_ADDRESS).then();
    fetchTokenInfoStake(getAppByChainId(state.chainId).STAKING_ADDRESS).then();
    fetchCheck(
      getAppByChainId(state.chainId).TOKEN_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ADDRESS
    ).then();
    getTotalReferrer();
    getMinimumStakeInDollars();
    getMinimumStakeInToken();
    getTotalPeriod();
    getAvailableReward();
    getAvailableRewardFromReferral();
    getClaimedReward();
    getClaimedRewardFromReferral();
    getPercentReferral();
    fetchTokenData();
  };

  const getParentReferral = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "getDirectSponsorOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setParentReferralAddress(res);
      if (!res || res === ADDRESS_ZERO) {
        const _refCode = searchParams.get("ref");
        getRow("Referals", [["refCode", "==", _refCode]]).then((res) => {
          setParentReferralAddress(res[0]?.address);
        });
      }
    });
  };
  const getTotalReferrer = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "totalReferrer",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setTotalReferrer(res);
    });
  };

  const getMinimumStakeInDollars = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "minimumStakeInDollars",
      []
    ).then((res) => {
      setMinimumStakeInDollars(res);
    });

    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "usdtAddress",
      []
    ).then((res) => {
      dynamicCall(
        res,
        getAppByChainId(state.chainId).ERC20_ABI,
        "decimals",
        []
      ).then((res2) => {
        setUsdtDecimal(res2);
      });
    });
  };

  const getMinimumStakeInToken = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "getMinimumStakeAmount",
      []
    ).then((res) => {
      const _res = new BigNumber(res);
      const _one = new BigNumber(1);
      const _newRes = _res.plus(_res.multipliedBy(0.01));
      setMinimumStakeInToken(_newRes.toFixed().toString());
    });
  };

  const getTotalPeriod = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "getTotalPeriodOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setTotalPeriod(res);
    });
  };

  const getAvailableReward = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "dividendOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setAvailableReward(res);
    });
  };

  const getAvailableRewardFromReferral = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "dividendOfReferrer",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setAvailableRewardFromReferral(res);
    });
  };

  const getClaimedReward = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "getTotalClaimedOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setClaimedReward(res);
    });
  };

  const getPercentReferral = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "percentForReferrer",
      []
    ).then((res) => {
      setPercentForReferral(res);
    });

    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "percentTaxDenominator",
      []
    ).then((res) => {
      setPercentDenominator(res);
    });
  };

  const getClaimedRewardFromReferral = () => {
    dynamicCall(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "getTotalClaimedReferrerOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setClaimedRewardFromReferral(res);
    });
  };
  const handleOnStakeAll = () => {
    const balance = new BigNumber(tokenBalance);
    const _newBalance = balance.minus(0.001);
    setAmountStake(_newBalance.toFixed(8).toString());
  };

  const checkIsValidAmountStake = () => {
    const balance = new BigNumber(parseFromWei(minimumStakeInToken));
    const amount = new BigNumber(amountStake);
    setIsValidAmountStake(amount.isGreaterThanOrEqualTo(balance));
  };

  const handleOnStakeHalf = () => {
    const balance = new BigNumber(tokenBalance);
    const half = balance.dividedBy(2);

    setAmountStake(half.toFixed(8).toString());
  };

  const handleOnMin = () => {
    setAmountStake(parseFromWei(minimumStakeInToken));
  };

  const handleOnSubmit = async () => {
    if (isNeedApprove) {
      fecthApprove(
        getAppByChainId(state.chainId).TOKEN_ADDRESS,
        getAppByChainId(state.chainId).STAKING_ADDRESS
      ).then();
    } else {
      dynamicSend(
        getAppByChainId(state.chainId).STAKING_ADDRESS,
        getAppByChainId(state.chainId).STAKING_ABI,
        "stake",
        [
          state.web3.utils.toWei(amountStake),
          state.web3.utils.toChecksumAddress(
            parentReferralAddress || ADDRESS_ZERO
          ),
        ],
        state.web3.utils.toWei(amountStake),
        tokenSymbol
      ).then((res) => {
        initialize();
        toast.success("Stake Success");
        // store data for referrer
        const _amountStake = new BigNumber(state.web3.utils.toWei(amountStake));
        const _reward = _amountStake
          .multipliedBy(percentForReferral)
          .dividedBy(percentDenominator);
        putData("ReferalChilds", res.txHash, {
          hash: res.txHash,
          directSponsor: parentReferralAddress || ADDRESS_ZERO,
          address: state.web3.utils.toChecksumAddress(state.account),
          amountStake: state.web3.utils.toWei(amountStake),
          amountReward: _reward.toFixed().toString(),
          amountSymbol: tokenSymbol,
        });
      });
    }
  };

  const handleOnSubmitClaimReward = async () => {
    dynamicSend(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "claimDividend",
      [],
      availableReward.toString(),
      tokenSymbol
    ).then((res) => {
      initialize();
    });
  };

  const handleOnSubmitClaimRewardReferral = async () => {
    dynamicSend(
      getAppByChainId(state.chainId).STAKING_ADDRESS,
      getAppByChainId(state.chainId).STAKING_ABI,
      "claimDividendReferrer",
      [],
      availableRewardFromReferral.toString(),
      tokenSymbol
    ).then((res) => {
      initialize();
    });
  };

  useEffect(() => {
    if (state.web3 && state.account) {
      initialize();
    }
  }, [state]);

  useEffect(() => {
    if (amountStake === 0) return;
    checkIsValidAmountStake();
  }, [amountStake]);

  useEffect(() => {
    getTotalStakeInDollars();
  }, [tokenBalanceStake]);

  const getTotalStakeInDollars = () => {
    if (tokenBalanceStake === 0 || !tokenBalanceStake) return;
    const amountIn = state.web3.utils.toWei(tokenBalanceStake);

    const path = [
      getAppByChainId(state.chainId).TOKEN_ADDRESS,
      getAppByChainId(state.chainId).USDT_ADDRESS,
    ];
    dynamicCall(
      getAppByChainId(state.chainId).ROUTER_ADDRESS,
      getAppByChainId(state.chainId).PANCAKE_ROUTER_ABI,
      "getAmountsOut",
      [amountIn, path]
    ).then((res) => {
      setAmountStakeInDollar(state.web3.utils.fromWei(res[1]));
    });
    return 123;
  };

  const fetchTokenData = async () => {
    try {
      const response = await axios.get(
        "https://api.geckoterminal.com/api/v2/networks/bsc/tokens/0x1F19c26a7F9b7A673348258d2fef0B7dF1Edef32/pools?page=1"
      );
      setPriceUSD(response.data?.data[0]?.attributes?.token_price_usd);
      setVolumeUSD24h(response.data.data[0]?.attributes?.volume_usd?.h24);
    } catch (error) {
      console.error("Error fetching token data:", error);
    }
  };

  return (
    <>
      <Header />
      <section className="relative px-4 py-4 overflow-hidden bg-white md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">
        <div className="w-full p-6 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
          <div className="flex flex-col items-center justify-between w-full lg:flex-row md:flex-col xl:flex-row">
            <div className="flex flex-col items-center gap-8 xl:flex-row lg:flex-row md:flex-row">
              <div className="flex items-center gap-3">
                <img
                  src="/images/icon-reward/fyt.png"
                  width="60px"
                  height="60px"
                  alt="opencoin"
                />
                <div className="space-y-1">
                  <h1 className="text-lg font-semibold">
                    ForYouTreaSure <span>(FYT)</span>
                  </h1>
                  <div className="flex items-center gap-3">
                    ${Number(priceUSD).toFixed(2)}{" "}
                    <span className="flex items-center gap-1 text-green-500">
                      <RiArrowUpLine /> 5%
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-lg font-semibold">Market Cap</h1>
                {/* <div className="flex items-center gap-3">$1,000,000</div> */}
                <div className="flex items-center gap-3">
                  Data will be add after listing
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-lg font-semibold">24h Volume</h1>
                <div className="flex items-center gap-1">
                  <img
                    src="/images/icon-reward/fyt.png"
                    width="25px"
                    height="25px"
                    alt="opencoin"
                  />{" "}
                  ${Number(volumeUSD24h).toFixed(2)}
                </div>
                {/* <div className="flex items-center gap-1"><img src="/images/icon-reward/fyt.png" width="25px" height="25px" alt="opencoin" /> $3,000,200 = $1,000,000</div> */}
              </div>
            </div>
            <div className="flex items-center gap-4 mt-6 xl:mt-0 lg:mt-0 md:mt-6">
              <a
                href={`https://bscscan.com/token/${
                  getAppByChainId(state.chainId).TOKEN_ADDRESS
                }}`}
                target="_blank"
                rel="noreferrer"
                className="px-6 py-4 font-semibold text-center text-white rounded-xl bg-grayc-900 dark:bg-grayc-950 hover:bg-grayc-900 dark:hover:bg-yellowc-500 dark:hover:text-gray-900"
              >
                Token Contract
              </a>
              <a
                href="/#"
                className="px-6 py-4 font-semibold text-center text-gray-900 bg-white rounded-xl hover:bg-gray-200 dark:hover:bg-grayc-950 dark:hover:text-white"
              >
                Buy FYT
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full gap-10 mt-12 md:flex-row lg:flex-row xl:flex-row">
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 xl:space-y-2.5 lg:space-y-2.5 md:space-y-3 space-y-4 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                <div>Your FYT Balance</div>
                <div className="flex items-center gap-1 text-end">
                  <img
                    src="/images/icon-reward/fyt.png"
                    height="25px"
                    width="25px"
                    alt="opencoin-small"
                  />
                  <LoadingWrapper
                    isLoading={isLoadingTokenInfo}
                    children={
                      <div>
                        {amountFormat(tokenBalance)} {tokenSymbol}
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                <div>Your Referral Count</div>
                <div className="flex items-center gap-1 text-end">
                  <LoadingWrapper
                    isLoading={isLoadingDynamicCall}
                    children={<div>{totalReferrer}</div>}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                <div>Your Period Count</div>
                <div className="flex items-center gap-1 text-end">
                  <LoadingWrapper
                    isLoading={isLoadingDynamicCall}
                    children={<div>{totalPeriod}</div>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 space-y-2 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <div className="flex items-center justify-between">
                <div>FYT Address</div>
                <div className="flex items-center gap-1 text-end">
                  <div className="flex items-center gap-2 px-4 py-2 font-bold bg-white border border-[#e3e3e4] dark:border-grayc-700 dark:bg-grayc-900 rounded-md">
                    <img
                      src="/images/icon-reward/bnb.svg"
                      width="15px"
                      height="15px"
                      alt="bsc"
                    />
                    <>
                      <a
                        href={`https://bscscan.com/token/${
                          getAppByChainId(state.chainId).TOKEN_ADDRESS
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="opencoin"
                        className="flex items-center gap-1 text-yellowc-500 dark:hover:text-yellowc-500 hover:text-yellowc-500"
                      >
                        {truncate(
                          getAppByChainId(state.chainId).TOKEN_ADDRESS,
                          4,
                          "...",
                          50
                        )}
                        <RiExternalLinkLine />
                      </a>
                      <ClipboardCopy
                        copyText={getAppByChainId(state.chainId).TOKEN_ADDRESS}
                      />
                    </>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>Staking Address</div>
                <div className="flex items-center gap-1 text-end">
                  <div className="flex items-center gap-2 px-4 py-2 font-bold bg-white border border-[#e3e3e4] dark:border-grayc-700 dark:bg-grayc-900 rounded-md">
                    <img
                      src="/images/icon-reward/bnb.svg"
                      width="15px"
                      height="15px"
                      alt="bsc"
                    />
                    <>
                      <a
                        href={`https://bscscan.com/token/${
                          getAppByChainId(state.chainId).STAKING_ADDRESS
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="opencoin"
                        className="flex items-center gap-1 text-yellowc-500 dark:hover:text-yellowc-500 hover:text-yellowc-500"
                      >
                        {truncate(
                          getAppByChainId(state.chainId).STAKING_ADDRESS,
                          4,
                          "...",
                          50
                        )}
                        <RiExternalLinkLine />
                      </a>
                      <ClipboardCopy
                        copyText={truncate(
                          getAppByChainId(state.chainId).STAKING_ADDRESS
                        )}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full gap-10 mt-12 md:flex-row lg:flex-row xl:flex-row">
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 space-y-3 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <div className="flex items-center justify-between w-full">
                <a
                  href="/#"
                  className="flex items-center gap-1 text-black dark:text-white hover:text-yellowc-600 dark:hover:text-yellowc-500"
                >
                  <RiExchangeDollarLine />
                  <div className="font-semibold">Get FYT</div>
                </a>
                <a
                  href="/#"
                  className="flex items-center gap-1 text-black dark:text-white hover:text-yellowc-600 dark:hover:text-yellowc-500"
                >
                  <RiTimer2Line />
                  <div className="font-semibold">History</div>
                </a>
              </div>

              <div className="p-4 bg-white dark:bg-grayc-800 rounded-2xl">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-3">
                    <button
                      type={"button"}
                      onClick={() => handleOnStakeAll()}
                      className="px-6 py-1 text-sm text-gray-800 bg-gray-100 dark:bg-grayc-900 dark:text-grayc-100 border-grayc-400 dark:border-gray-900 hover:border-gray-700 dark:hover:border-grayc-500 rounded-xl"
                    >
                      Max
                    </button>
                    <button
                      type={"button"}
                      onClick={() => handleOnStakeHalf()}
                      className="px-6 py-1 text-sm text-gray-800 bg-gray-100 dark:bg-grayc-900 dark:text-grayc-100 border-grayc-400 dark:border-gray-900 hover:border-gray-700 dark:hover:border-grayc-500 rounded-xl"
                    >
                      Half
                    </button>
                    <button
                      type={"button"}
                      onClick={() => handleOnMin()}
                      className="px-6 py-1 text-sm text-gray-800 bg-gray-100 dark:bg-grayc-900 dark:text-grayc-100 border-grayc-400 dark:border-gray-900 hover:border-gray-700 dark:hover:border-grayc-500 rounded-xl"
                    >
                      Min
                    </button>
                  </div>

                  <div className="flex items-center gap-2">
                    <RiWalletLine />
                    <div>
                      {amountFormat(tokenBalance)} {tokenSymbol}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between w-full gap-4 my-4">
                  <CurrencyInput
                    className="w-8/12 gap-2 px-4 py-2 bg-gray-100 xl:w-10/12 lg:w-10/12 md:w-8/12 border-grayc-600 dark:border-grayc-900 dark:bg-grayc-900 rounded-xl"
                    placeholder="0"
                    value={amountStake}
                    onValueChange={(value) => {
                      setAmountStake(value);
                    }}
                  />
                  {/*<input type="number"*/}
                  {/*    min="0"*/}
                  {/*    onKeyDown={(e) =>*/}
                  {/*        ["ArrowUp", "ArrowDown", "e", "E", ".", "-", ",", "_", "+"].includes(e.key) && e.preventDefault()*/}
                  {/*    }*/}
                  {/*    onPaste={(e) => {*/}
                  {/*        e.preventDefault();*/}
                  {/*        return false;*/}
                  {/*    }}*/}
                  {/*       value={amountStake}*/}
                  {/*    placeholder="0"*/}
                  {/*    className="w-8/12 gap-2 px-4 py-2 bg-gray-100 xl:w-10/12 lg:w-10/12 md:w-8/12 border-grayc-600 dark:border-grayc-900 dark:bg-grayc-900 rounded-xl"*/}
                  {/*/>*/}
                  <div className="flex items-center w-4/12 gap-1 px-4 py-2 bg-gray-100 md:w-4/12 lg:w-2/12 xl:w-2/12 border-grayc-600 dark:border-gracy-900 dark:bg-grayc-900 rounded-xl">
                    <img
                      src="/images/icon-reward/usdt.png"
                      width="25px"
                      height="25px"
                      alt="opencoin-sm"
                    />
                    <div>USDT</div>
                  </div>
                </div>
                <div>
                  {isValidAmountStake === false && (
                    <small className={"text-red-800"}>Invalid Amount</small>
                  )}
                </div>
                <div>
                  Minimum Stake:{" "}
                  {parseFromWei(minimumStakeInDollars, usdtDecimal, 6)} USDT ={" "}
                  {amountFormat(parseFromWei(minimumStakeInToken))}{" "}
                  {tokenSymbol}
                </div>
              </div>

              <div className="flex items-center justify-between w-full gap-4 pt-3">
                <button
                  type={"button"}
                  className={
                    "w-full flex items-center justify-center gap-2 px-6 py-3 text-center text-white rounded-3xl " +
                    (isValidAmountStake
                      ? "bg-grayc-950 hover:bg-yellowc-500 hover:text-gray-900"
                      : "bg-gray-400 text-gray-800")
                  }
                  disabled={isValidAmountStake === false}
                  onClick={handleOnSubmit}
                >
                  {isNeedApprove ? "APPROVE" : "COMMIT"}
                  <div className={""}>
                    <ClipLoader
                      color={"#f59e0b"}
                      loading={isLoadingCheck || isLoadingSend}
                      size={20}
                    />
                  </div>
                </button>
                {/*<button className="w-full px-6 py-3 text-center text-white bg-grayc-900 hover:bg-grayc-950 dark:hover:bg-grayc-950 dark:bg-grayc-800 rounded-3xl">Unstake</button>*/}
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 space-y-3 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <h1 className="mb-5 text-lg font-semibold">Commit Information</h1>
              <div className="space-y-3">
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Your Commited</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/fyt.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <LoadingWrapper
                      isLoading={isLoadingTokenInfoStake}
                      children={
                        <div>
                          {amountFormat(tokenBalanceStake)} {tokenSymbolStake}{" "}
                          (est {amountFormat(amountStakeInDollar, 2)} USDT)
                          {/*({tokenBalanceStake / minimumStakeInDollars})*/}
                          {/*({getTotalStakeInDollars(tokenBalanceStake)})*/}
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Available Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/fyt.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <LoadingWrapper
                      isLoading={
                        isLoadingTokenInfoStake || isLoadingDynamicCall
                      }
                      children={
                        <div>
                          {amountFormat(parseFromWei(availableReward))}{" "}
                          {tokenSymbol} ($
                          {Number(
                            amountFormat(parseFromWei(availableReward)) *
                              priceUSD
                          )}
                          )
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Claimed Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/fyt.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <LoadingWrapper
                      isLoading={
                        isLoadingTokenInfoStake || isLoadingDynamicCall
                      }
                      children={
                        <div>
                          {amountFormat(parseFromWei(claimedReward))}{" "}
                          {tokenSymbol} ($
                          {Number(
                            amountFormat(parseFromWei(claimedReward)) * priceUSD
                          )}
                          )
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-4 pt-3">
                <button
                  type={"button"}
                  className="flex items-center gap-2 px-8 py-3 text-gray-900 bg-yellowc-400 hover:bg-yellowc-500 rounded-xl"
                  onClick={handleOnSubmitClaimReward}
                >
                  <div>Claim Reward</div>
                  <div className={"flex items-center"}>
                    <ClipLoader
                      color={"#f59e0b"}
                      loading={isLoadingCheck || isLoadingSend}
                      size={20}
                    />
                  </div>
                </button>
                {/*<button className="px-8 py-3 text-gray-900 bg-gray-400 hover:bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 rounded-xl">*/}
                {/*    No Reward*/}
                {/*</button>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
