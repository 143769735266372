import { useState, useEffect } from "react";
import { getAppByChainId } from "../libs/Env";
import { toast } from "react-toastify";
import { useSupabase } from "./useSupabase";
import {
  useAppKitNetwork,
  useAppKitProvider,
  useAppKitAccount,
} from "@reown/appkit/react";
import { Contract, providers, utils } from "ethers";

export const useCheckIsNeedApprove = () => {
  const [amountAllowance, setAmountAllowance] = useState(null);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [isNeedApprove, setIsNeedApprove] = useState(false);
  const [txHashApprove, setTxHashApprove] = useState(null);
  const { putData } = useSupabase();
  const { chainId } = useAppKitNetwork();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const fetchCheckNeedApprove = async (tokenAddress, to) => {
    if (!isConnected || !address) return;
    setIsLoadingCheck(true);
    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const token = new Contract(
        tokenAddress,
        getAppByChainId(chainId).ERC20_ABI,
        signer
      );
      const allowance = await token.allowance(address, to);
      const balance = await token.balanceOf(address);

      setIsNeedApprove(balance.gte(allowance));
    } catch (error) {
      console.error("Error checking approval:", error);
    } finally {
      setIsLoadingCheck(false);
    }
  };

  const fecthApprove = async (tokenAddress, to) => {
    if (!isConnected || !address) return;
    setIsLoadingCheck(true);
    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const token = new Contract(
        tokenAddress,
        getAppByChainId(chainId).ERC20_ABI,
        signer
      );
      const balance = await token.balanceOf(address);
      const tx = await token.approve(to, balance.mul(2));

      const receipt = await tx.wait();
      await putData({
        table: "fyt_transactions",
        data: {
          account: address,
          chain_id: chainId,
          amount: balance.toString(),
          amount_symbol: await token.symbol(),
          hash: receipt.transactionHash,
          type: "Approve",
          data: {
            token_address: tokenAddress,
            to: to,
            amount: balance.toString(),
          },
        },
        key: "hash",
      });
      setTxHashApprove(receipt.transactionHash);
      toast.success("Transaction Receipt", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error approving transaction:", error);
      toast.error("Transaction Canceled", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoadingCheck(false);
    }

    toast.success("Transaction Submitted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return [
    { amountAllowance, isNeedApprove, isLoadingCheck, txHashApprove },
    fetchCheckNeedApprove,
    fecthApprove,
  ];
};

export const useTransferToken = () => {
  const [isLoadingTransfer, setIsLoading] = useState(false);
  const [txHashTransfer, setTxHash] = useState(null);
  const { chainId } = useAppKitNetwork();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const fetchTransfer = async (tokenAddress, to, amount) => {
    if (!isConnected || !address) return;
    setIsLoading(true);
    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const token = new Contract(
        tokenAddress,
        getAppByChainId(chainId).ERC20_ABI,
        signer
      );
      const tx = await token.transfer(to, utils.parseUnits(amount, 18));

      const receipt = await tx.wait();
      setTxHash(receipt.transactionHash);
      toast.success(
        "Transaction Receipt, TX Hash =" + receipt.transactionHash,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.error("Error transferring token:", error);
      toast.error("Transaction Canceled", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }

    toast.success("Transaction Submitted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return [{ isLoadingTransfer, txHashTransfer }, fetchTransfer];
};

export const useGetERC20Detail = () => {
  const [isLoadingTokenInfo, setIsLoading] = useState(false);
  const [tokenName, setTokenName] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenDecimal, setTokenDecimal] = useState(null);
  const [tokenSupply, setTokenSupply] = useState(null);
  const [tokenBalance, setTokenbalance] = useState(0);
  const { chainId } = useAppKitNetwork();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const fetchTokenInfo = async (tokenAddress) => {
    // console.log("Address:", address);
    // console.log("isConnected:", isConnected);
    if (!isConnected || !address) return;
    setIsLoading(true);

    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const token = new Contract(
        tokenAddress,
        getAppByChainId(chainId).ERC20_ABI,
        signer
      );

      setTokenName(await token.name());
      setTokenSymbol(await token.symbol());
      setTokenSupply(await token.totalSupply());

      const dec = await token.decimals();
      setTokenDecimal(dec);

      const balance = await token.balanceOf(address);
      setTokenbalance(utils.formatUnits(balance, dec));
    } catch (error) {
      console.error("Error fetching token info:", error);
      toast.error("Failed to fetch token info");
    } finally {
      setIsLoading(false);
    }
  };

  return [
    {
      tokenName,
      tokenSymbol,
      tokenDecimal,
      tokenSupply,
      tokenBalance,
      isLoadingTokenInfo,
    },
    fetchTokenInfo,
  ];
};

export const useDynamicCallFunction = () => {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const dynamicCall = async (address, abi, methodName, params = []) => {
    if (!isConnected || !address) return;
    setIsLoading(true);
    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      const contract = new Contract(address, abi, ethersProvider);

      const method = contract[methodName];
      if (!method) {
        throw new Error(`Method ${methodName} not found`);
      }

      let response = await method(...params);
      if (methodName === "balanceOf") {
        const decimals = await contract.decimals();
        response = utils.formatUnits(response, decimals);
      }

      setResult(response);
      return response;
    } catch (error) {
      console.error("Error in dynamic call:", error);
      setResult(null);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ result, isLoading }, dynamicCall];
};

export const useDynamicSendFunction = () => {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { putData } = useSupabase();
  const { chainId } = useAppKitNetwork();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const dynamicSend = async (
    address,
    abi,
    methodName,
    params = [],
    amountInWei = 0,
    amountSymbol = "-"
  ) => {
    if (!isConnected || !address) return;
    setIsLoading(true);
    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const contract = new Contract(address, abi, signer);

      const method = contract[methodName];
      if (!method) {
        throw new Error(`Method ${methodName} not found`);
      }

      const tx = await method(...params);
      const receipt = await tx.wait();

      await putData({
        table: "fyt_transactions",
        data: {
          account: address,
          chain_id: chainId,
          amount: amountInWei.toString(),
          amount_symbol: amountSymbol,
          hash: receipt.transactionHash,
          type:
            methodName.toString().charAt(0).toUpperCase() +
            methodName.toString().slice(1),
          data: {
            to: address,
            ...params,
          },
        },
        key: "hash",
      });

      setResult(receipt);
      toast.success("Transaction Submitted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return {
        txHash: receipt.transactionHash,
        response: receipt,
      };
    } catch (error) {
      console.error("Error in dynamic send:", error);
      setResult(null);
      toast.error("Transaction Canceled", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return [{ result, isLoading }, dynamicSend];
};

export const useGetListToken = () => {
  const [isLoadingListToken, setisLoading] = useState(false);
  const [listToken, setListToken] = useState([]);
  const { chainId } = useAppKitNetwork();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const fetchListToken = async (addresses = []) => {
    if (!isConnected || !address) return;
    setisLoading(true);
    try {
      const ethersProvider = new providers.Web3Provider(walletProvider);
      let tmpList = [];
      for (let i = 0; i < addresses.length; i++) {
        const token = new Contract(
          addresses[i],
          getAppByChainId(chainId).ERC20_ABI,
          ethersProvider
        );
        const dec = await token.decimals();
        const tmp = {
          address: addresses[i],
          name: await token.name(),
          symbol: await token.symbol(),
          supply: await token.totalSupply(),
          decimal: dec,
        };
        tmpList.push(tmp);
      }
      setListToken(tmpList);
    } catch (error) {
      console.error("Error fetching token list:", error);
    } finally {
      setisLoading(false);
    }
  };

  return [{ listToken, isLoadingListToken }, fetchListToken, setListToken];
};

/** Custom web3 hook */

// export const useGetTotalDonation = (scamAddress) => {
//   const [state, dispatch] = useContext(Context);
//   const [isLoadingTotalDonation, setIsLoading] = useState(false);
//   const [amountDonation, setAmountDonation] = useState(null);
//   const [totalContributor, setTotalContributor] = useState(0);

//   useEffect(() => {
//     if (state && state.storeHash) {
//     }
//   }, [state]);

//   return [{ amountDonation, totalContributor, isLoadingTotalDonation }];
// };
